import React from 'react'
import {FaMotorcycle as DeliveryIcon} from 'react-icons/fa'
import {MdRestaurant as ServeIcon, MdShoppingBag as GoIcon} from 'react-icons/md'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import dark from './dark.module.css'
import lightStyles from './light.module.css'
import tiramisuStyles from './tiramisu.module.css'

const selectStyle = (light: boolean, layout: string) => {
  if (layout === 'tiramisu') {
    return tiramisuStyles
  }
  return light ? lightStyles : dark
}

export default function DeliveryType(props) {
  const {light, onChange, value, omitOnChangeIfOnlyOneOption = false} = props
  const {t} = useTranslation('generic', {keyPrefix: 'fields.deliveryType'})
  const {website} = useInitialData()

  const renderOption = code => {
    const labels = {
      delivery: t('delivery'),
      go: t('go'),
      serve: t('serve')
    }
    const size = 24
    const icons = {
      delivery: <DeliveryIcon size={size} />,
      go: <GoIcon size={size} />,
      serve: <ServeIcon size={size} />
    }

    const selected = value === code
    const styles = selectStyle(light, website.layout)
    return (
      <div className={selected ? styles.selected : styles.option} onClick={() => onChange(code)}>
        <div className={styles.icon}>{icons[code]}</div>
        <div className={styles.label}>{labels[code]}</div>
      </div>
    )
  }

  const options = website.deliveryTypes || []
  const styles = light ? lightStyles : dark
  if (options.length === 1 && !omitOnChangeIfOnlyOneOption) {
    if (value !== options[0]) {
      onChange(options[0], true)
    }
    return null
  }
  return (
    <div className={styles.container}>
      {options.includes('delivery') && renderOption('delivery')}
      {options.includes('go') && renderOption('go')}
      {options.includes('serve') && renderOption('serve')}
    </div>
  )
}
