import gql from 'graphql-tag'

export const preferencesForAddressContext = gql`
  fragment preferencesForAddressContext on Preference {
    _id
    deliveryType
    tableName
    storeId
    store {
      _id
      name
      minimumAnticipationDays
      availableScheduleDaysOptions {
        label
        value
      }
      schedule {
        itsOpenNow: itsAvailableAt(websiteId: $websiteId)
      }
    }
    placeId
    address {
      _id
      address
      addressSecondary
      addressLine2
    }
    menuId
  }
`
