import {useState} from 'react'
import {
  SetDeliveryTypeInPreferencesMutation,
  SetDeliveryTypeInPreferencesMutationVariables
} from '@data/__generated__/types.main'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {gql, useMutate} from 'apollo-hooks'
import {useTranslation} from 'next-i18next'

import {preferencesForAddressContext} from './fragments/preferencesForAddressContext.main'

export default function useSetDeliveryTypeInPreferences() {
  const websiteId = useWebsiteId()
  const mutate = useMutate()
  const [loading, setLoading] = useState(false)
  const showMessage = useMessage()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.edit'})

  const setDeliveryTypeInPreferences = async (
    deliveryType: string
  ): Promise<SetDeliveryTypeInPreferencesMutation> => {
    setLoading(true)

    const result = await mutate<
      SetDeliveryTypeInPreferencesMutation,
      SetDeliveryTypeInPreferencesMutationVariables
    >({
      mutation: gql`
        mutation setDeliveryTypeInPreferences($websiteId: ID, $deliveryType: ID) {
          setUserPreferences(websiteId: $websiteId, deliveryType: $deliveryType) {
            _id
            ...preferencesForAddressContext
          }
        }
        ${preferencesForAddressContext}
      `,
      variables: {deliveryType, websiteId}
    }).finally(() => setLoading(false))

    // showMessage(t('success'))

    return result
  }

  return {setDeliveryTypeInPreferences, loading}
}
