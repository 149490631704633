import useWidth from './useWidth'

export type ScreenSize = 'mobile' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export default function useScreenSize(): ScreenSize {
  const width = useWidth()

  // Using Tailwind breakpoints and semantics
  if (width < 640) return 'mobile'
  if (width < 768) return 'sm'
  if (width < 1024) return 'md'
  if (width < 1280) return 'lg'
  if (width < 1536) return 'xl'
  return '2xl'
}
