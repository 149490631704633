import React, {Suspense} from 'react'
import useSetDeliveryTypeInPreferences from '@components/DeliveryWizard/internals/hooks/useSetDeliveryTypeInPreferences.main'
import DeliveryType from '@components/fields/DeliveryType'
import Loading from '@components/Loading'
import SuspenseLoading from '@components/SuspenseLoading/Loading'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'
import useScreenSize from '@hooks/useScreenSize'
import useState from '@hooks/useState'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'
import get from 'lodash/get'
import {useRouter} from 'next/router'

import styles from './styles.module.css'

const OptionsWrapper = ({useBox = false}) => {
  const router = useRouter()
  const {setDeliveryTypeInPreferences} = useSetDeliveryTypeInPreferences()
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null)

  const {website} = useInitialData()

  if (get(website, 'deliveryTypes', []).length === 0) return null

  return (
    <>
      <Suspense fallback={<SuspenseLoading />}>
        <div className="w-80">
          <DeliveryType
            omitOnChangeIfOnlyOneOption={true}
            onChange={newSelectedDeliveryType => {
              setSelectedDeliveryType(newSelectedDeliveryType)
              setDeliveryTypeInPreferences(newSelectedDeliveryType).finally(() =>
                router.push('/pedir?addressModal=true')
              )
            }}
            value={selectedDeliveryType}
            light
          />
        </div>
        <a className={styles.button} onClick={() => router.push('/pedir')}>
          Pedir
        </a>
      </Suspense>
    </>
  )
}

export default function StartOrdering(props) {
  const {backgroundImage, title, useBox} = props
  const screenSize = useScreenSize()
  const isMobile = screenSize === 'mobile'

  const width = isMobile ? 800 : 1600
  const height = isMobile ? 600 : 1200

  const url = useResizedImageUrlSsr({fileId: backgroundImage._id, width, height})

  const backgroundURL = url 

  if (useBox) {
    return (
      <div className={styles.containerBox} style={{backgroundImage: `url(${backgroundURL})`}}>
        <Container className={styles.boxContainer}>
          <div className={styles.box}>
            <h1 className={styles.titleBox}>{title}</h1>
            <Suspense fallback={<Loading />}>
              <OptionsWrapper useBox />
            </Suspense>
          </div>
        </Container>
      </div>
    )
  } else {
    return (
      <div className={styles.container} style={{backgroundImage: `url(${backgroundURL})`}}>
        <div className={styles.overlay} />
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <br />
          <Suspense fallback={<Loading />}>
            <OptionsWrapper />
          </Suspense>
        </div>
      </div>
    )
  }
}
